
@import "./variables.scss";

.list-component li {
    border: none;
    border-bottom: 1px solid $listBorderColor;
}
  
.menu-items-container {
    .menu-item {
        align-items: center; 
    }

    .menu-icon {
        width : 33px;
        height: 33px;
        padding-right: 10px;
        text-align: center;
        background-repeat: no-repeat;

        &.icon-font {
            font-size: 1.4em;
        }
        &.fa {
            font-size: .9em;
        }
    }
    .happening-menu-item {
        .menu-text {
            color: $color1
        }
    }
}

// Raise text padding due to printania font
.list-el-text {
    padding: .4em 0;
}
.toolbar > .center {
    padding-top: .2em;
}
.type-bar-label {
    padding-top: 3px;
}
.is-android .type-bar-count {
    padding: 0 4px 0 3px;
}

// Category counter
.is-android .list-el-counter > span {
    padding: .5em .3em .2em;
}
.is-ie .list-el-counter > span {
    padding-top: .2em;
    padding-bottom: .4em;
}
.list-el-counter > span {
    padding-top: .4em;
    padding-bottom: .2em;
}

.prop-name.title .prop-desc {
    padding-top: .2em;
}

.ps-bar {
    &.horizontal-separator {
        padding: 10px 5px 10px 5px;
        font-size: 1.2em;
        text-transform: uppercase;
        letter-spacing: 1.1px;
    }
}

div[role="dialog"]{
    .search-field-input-container {
        border-radius: 10px;
        padding: 5px 0;
    }
}

.ps-row {
    .search-field-container {
        padding: 0.6em 0.4em 0.6em 0.4em;
        .search-field-clear {
            margin: 5px;
            border-radius: 100%;
        }
    }
}

.type-bar {
    .type-bar-count span{
        background-color: #fff;
        border-radius: 0;
        padding: 4px ;
        font-size: 0.77rem;
        color: $color2;
    }
    .type-bar-label {
        text-align: left;
        padding: 0;
        letter-spacing: 1.1px;
    }
    &.horizontal-separator {
        background-color: $color2;
        padding: 7px 8px
    }
}
.list-page-content, .list-search-content, #search-page-container {
    .list-component {
        .list-el-text {
            max-width: 94.2%;
            & > span {
                color: black;
                text-transform: uppercase;
            }
            .list-el-details {
                .text-minor{
                    color: black;
                    font-size: inherit;
                }
                text-transform: capitalize;
            }
        }
        li {
            padding: 1rem 0 1rem 0.5em;
        }
        li[data-type*="categories"] {
            padding: 0.8rem 0 0.8rem 0.5em;
            min-height: 0;
            .list-el-text {
                > span {
                    text-transform: capitalize;
                }
            }
        }
    }
}
.list-dialog-content{
    .list-component {
        > li {
            padding-left: 8px;
            padding-right: 5px;
            border-left: none;
            border-right: none;
            border-top: none;
            border-bottom: 1px solid $color2;
            &:last-child {
                border-bottom: none;
            }
            .list-el-text {
                font-variation-settings: 'wght' 800;
                font-size: large;
                max-width: 95%;
                > span {
                    text-transform: uppercase;
                }
            }
            .text-minor{
                font-variation-settings: 'wght' 500;
                text-transform: capitalize;
                color: black;
            }
            .list-el-btn {
                &.go-to {
                    padding: 7px 6px;
                }
            }

        }
    }
}
.MuiDialog-root {
    .MuiBackdrop-root {
        background-color: rgba(250,250,250,0.7);
    }
}
.MuiDialog-container {
    font-family: 'Urbanist';
    height: auto !important;
    .MuiPaper-root {
        .MuiDialogTitle-root {
            .MuiTypography-root {
                font-family: 'Urbanist';
                font-variation-settings: 'wght' 800;
            }
        }
    }
}
.MuiDialog-root.dialog-min-width {
    .MuiPaper-root {
        width: 100%;
        max-width: 800px;
        margin-right: 8px;
        margin-left: 8px;
        padding-bottom: 10px;
        border: 1px solid $color1;
        border-radius: 0 0 30px 0;
        #sp-inner {
            .MuiListItem-root.MuiListItem-gutters{
                padding-left: calc(30px + 0.4em);
                padding-right: 16px;
                border-bottom: 0;
            }
            .sp-buttons-container {
                margin: 0 35px;
                align-items: initial;
                flex: 1 35px;
                > div {
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    margin: 0;
                    padding: 0;
                    border-radius: 6px;
                    text-align: center;
                    text-transform: uppercase;
                    font-variation-settings: 'wght' 500;
                    letter-spacing: 1.1px;
                    font-size: 0.8rem;
                    &:first-child {
                        margin-right: 10px;
                    }
                    &:last-child {
                        margin-left: 10px;
                    }
                    &.btn-edit-start {
                        background-color: $color2;
                    }
                }
            }
        }
    }

}

.ps-selected {
    margin: 5px 30px;
    padding-bottom: 0;
    .ps-selected-text{
        > div:nth-child(1){
            font-variation-settings: 'wght' 800;
        }
    }
}

.app-toolbar {
    .left {
        z-index: 1;
        flex: 0 3rem;
        > span {
            flex: 1;
        }
    }
    .center {
        position: absolute;
        width: 100%;
        font-size: 1.1rem;
        letter-spacing: 1.1px;
        z-index: 0;
        text-transform: uppercase;
        padding: 0;
        > span {
            padding: 0 4.5rem ;
            text-overflow: ellipsis;
            overflow: hidden;
            font-variation-settings: 'wght' 900;
        }
    }
    .right {
        z-index: 1;
        .app-toolbar-icon {
            > span {
                height: auto;
                margin: 0;
            }
        }
    }
    .app-toolbar-icon {
        span {
            &.arrow_back {
                display: flex;
                flex: 1;
                span{
                    display: inherit;
                    flex: 1;
                    svg {
                        flex: 1;
                        margin: 0;
                    }
                }
                font-size: 1rem
            }
        }
    }
}
.menu-items-container{
    .menu-text {
        font-variation-settings: 'wght' 500;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        color: $BorderMenuColor ;
    }
}

.fiche { 
    .prop-description {
        color: black;
    }
    .prop-name.title.title-flex {
        padding: 0.75em 0;
        justify-content: flex-end;
        .prop-desc {
            font-variation-settings: 'wght' 500;
            text-transform: capitalize;
            justify-content: center;
            position: absolute;
            width: 100%;
            line-height: normal;
            left: 0;
            line-height: normal;
            .title-label {
                width: 70%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            }
        }
        .prop-add-btn {
            display: flex;
            align-items: center;
            > * {
                flex: 1;
                font-size: 1.2rem;
                margin: 0 0.2rem;
                &.share-btn {
                    margin: 0 0.2rem;
                }
                &.fav-btn {
                    background-color: #fff;
                    width: 1em!important;
                }
            }
        }
    }
}

.fiche {
    .fiche-separator {
        display: none;
    }
    .all-informations{
        color: $color2;
        .colored-link {
            color: inherit !important;
        }
        .localisations {
            font-size: 1.1rem;
            font-variation-settings: 'wght' 500;
            li {
                margin: 0;
                color: black
            }
        }
        .free-row {
            font-size: 1.1rem;
            font-variation-settings: 'wght' 700;
            border-bottom: 0;
            .prop-right {
                font-variation-settings: 'wght' 500;
                display: flex;
                align-items: center;
                color: black;
            }
        }
        .prop-left {
            margin: 0;
        }
    }
}

.menu-items-container > li {
    border:  none !important ;
    border-left: 1px solid $BorderMenuColor!important ;
    border-right: 1px solid $BorderMenuColor!important;
    border-top: 1px solid $BorderMenuColor !important;

}

.menu-items-container > li:last-child {
    border:  none !important ;
    border:1px solid $BorderMenuAppversion !important ;

}
.list-el-text {
    display    : flex;
    flex-direction: column;
    flex-grow  : 1;
    word-break: break-word;
    max-width: 55%;
}
.list-component li {
    display: flex;
    padding: 0.7em 0 0.7em 0.5em;
    align-items: center;
    border: none;
    border-left: 1px solid #76777A;
    border-right: 1px solid #76777A;
    border-top: 1px solid #76777A;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    min-height: 100px;
    box-sizing: border-box;
    &.horizontal-separator {
        min-height: 0;
    }
}
.list-el-chevron {
    // padding: .1em .3em 0 .1em;
    color  : $listChevronColor;
    // position: absolute;
    // right: 2px;
    align-self: center;
    height: auto;
    width: 1.7rem;
}

.no-flag {
    &::before {
        font-size: 1.6rem;
        font-variation-settings: 'wght' 600;
    }
}

.generic-modal-content-lang {
    .generic-btn-cancel {
        text-transform: none;
        color: white;
        border: 1px solid lightgrey;
        border-radius: 3px;
        background-color: $color2  ;
        border-radius: 8px ;
        cursor: pointer;
        font-variation-settings: 'wght' 500;
        text-transform: uppercase;
    }
}

[id=choose-lang-inline-container] {
    margin-top: 1.2em;
}
.fiche {
    .fiche-related-data {
        margin: 0.6em 0 0 1em;
        padding: 0 0.3em 0.6em 0;
        .prop-img {
            margin: 0;
            padding: 0;
            .prop-right {
                align-items: center;
                display: flex;
            }
        }
    }
}

.side-index {
    flex-basis: 2rem;
}

.type-bar {    
    .type-bar-icon {
        background-color: #FFF!important;
        font-size: 1.7rem;
        margin: 0;
    }
}

#search-place-dialog {
    
    .ps-bar {
        &.horizontal-separator{
            font-variation-settings: 'wght' 800;
        }
    }
    .type-bar-label{
        text-transform: uppercase;
    }
}

.list-dialog {
    .modal-title {
        background-color: $color1;
        color: #fff;
        text-transform: uppercase;
    }
    .ps-selected {
        .ps-selected-text {
            > div {
                color: black;
                text-transform: capitalize;
                &:first-child {
                    font-size: 1.3rem;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.type-bar.horizontal-separator{
    .type-bar-count {
        display: flex;
        flex-basis: 0rem;
    }
}
.sp-buttons-container {
    .btn-edit-start {
        font-size: .8em;
    }
}
.menu-item{
    &.menu-item-switch {
        margin-left: 0;
    }
}